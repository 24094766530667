.nav-user-icon {
    max-width: 30px;
}

.square {
    width: 3em;
    height: 2em;
    background: white;
    margin: 5px;
    padding: 5px;
    text-align: center;
    display: inline-block;
    cursor:pointer;
}

.square.selected {
    background: lightblue;
}

div.hour-picker {
    background-color: white;
    display: inline-flex;
    border: 1px solid #ccc;
    color: #555;
}

div.hour-picker > input {
    color: #555;
    text-align: center;
    width: 60px;
}
  
.time-interval {
    display: inline-flex;
    margin: 5px;
    margin-right: 10px;
}

.time-interval-error {
    border: 2px solid rgb(194, 15, 15);
}